var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"628b492632e4722295ea5a4bbe7e6e880e41871b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

import { clientConfig } from './config'

Sentry.init({
  dsn: clientConfig.sentry.dsn,
  environment: clientConfig.environment,
  // Replay may only be enabled for the client-side
  integrations:
    clientConfig.environment == 'local' ? [] : [new Sentry.Replay()],

  tracesSampleRate: clientConfig.environment == 'local' ? 0 : 1.0,
  replaysSessionSampleRate: clientConfig.environment == 'local' ? 0 : 0.1,
  replaysOnErrorSampleRate: clientConfig.environment == 'local' ? 0 : 1.0,
  profilesSampleRate: clientConfig.environment == 'local' ? 0 : 1.0,
  sampleRate: clientConfig.environment == 'local' ? 0 : 1.0,
})
